<template>
	<banner-style1 :position="9"></banner-style1>
	<div class="work-detail">
		<div class="work-detail-con c w">
			<el-breadcrumb separator=">">
				<el-breadcrumb-item :to="{ path: '/' }">
					<el-icon style="vertical-align: middle">
						<HomeFilled />
					</el-icon>首页
				</el-breadcrumb-item>
				<el-breadcrumb-item>{{type==='1'?'非命题赛事':'命题赛事'}}</el-breadcrumb-item>
				<el-breadcrumb-item v-if="detail.name">{{ detail.name }}</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="work-content">
				<h1 v-if="detail.name">{{ detail.name }}</h1>
				<div class="poster" v-if="detail.bgImg && type==='2'">
					<p class="title bg-title">主题海报</p>
					<img :src="detail.bgImg" alt="">
				</div>
				<div class="time" v-if="detail.startDate">
					<p>投稿时间</p>
					<p>{{ detail.startDate}} - {{ detail.endDate}}</p>
				</div>
				<div class="time" v-if="detail.interpretation">
					<p v-html="detail.interpretation"></p>
				</div>
				<div class="content-html">
					<p v-html="detail.content"></p>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import {
		HomeFilled
	} from '@element-plus/icons-vue'
	import {
		ref
	} from 'vue'
	import {
		useRoute
	} from 'vue-router';
	import {
		getCompetitionInfo
	} from '@/api/competition'
	import {
		formatTime
	} from '@/utils/date'
	const route = useRoute() // type 1 非命题 2 命题
	const detail = ref('')
	let type = route.query.type
	getCompetitionInfo({
		id: route.query.id
	}).then(res => {
		console.log(res);
		detail.value = res.data
	})
</script>

<style lang="scss" scoped>
	.work-detail {
		padding-bottom: 80px;
	}

	.el-breadcrumb {
		font-size: 14px;
		padding-top: 30px;
	}

	.el-breadcrumb__separator {
		color: #000;
	}

	.work-content {
		color: #333;
		.time {
			margin-top: 30px;
		}
		h1 {
			font-size: 36px;
			font-weight: 500;
			margin: 20px 0;
		}

		.title {
			font-size: 24px;
			margin-top: 30px;
			margin-bottom: 20px;
		}

		.poster {

			img {
				display: block;
				height: 345px;
			}
		}

		.content-html {
			margin-top: 30px;
		}

	}
</style>
